'use client';

import { ReactNode, Suspense } from 'react';
import { Provider } from 'react-redux';
import App from '@/App';
import Loading from '@/components/layouts/loading';
import store from '@/store';
interface IProps {
  children?: ReactNode;
}
const ProviderComponent = ({
  children
}: IProps) => {
  return <Provider store={store} data-sentry-element="Provider" data-sentry-component="ProviderComponent" data-sentry-source-file="provider-component.tsx">
      <Suspense fallback={<Loading />} data-sentry-element="Suspense" data-sentry-source-file="provider-component.tsx">
        <App data-sentry-element="App" data-sentry-source-file="provider-component.tsx">{children} </App>
      </Suspense>
    </Provider>;
};
export default ProviderComponent;